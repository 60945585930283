import { FormattedMessage } from 'react-intl';

import { faInfoCircle } from '@trustyou/fortawesome/pro-regular-svg-icons';

import { IconWithTooltip } from '../icon-with-tooltip';

export function InfoIcon() {
  return (
    <IconWithTooltip
      tooltip={
        <FormattedMessage
          // FIXME: Rename this translation key; the previous id used here was "inbox.search.info"
          id="inbox.settings.notifications.keywords.helper-text"
          defaultMessage="Separate keywords with a comma"
        />
      }
      icon={faInfoCircle}
      size="xl"
      IconProps={{ sx: { color: (theme) => theme.palette.action.active } }}
    />
  );
}
