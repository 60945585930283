import type {
  BookingConnectionResponse,
  GoogleConnectionResponse,
  ReviewProvider,
} from '@trustyou/shared';

import type {
  BasicEntity,
  CompetitorsUploadResponse,
  CreateEntity,
  CreateEntityResponse,
  EditEntity,
  EditEntityResponse,
  EntitiesPayload,
  EntitiesResponse,
  Entity,
  EntityBookingConnectionRequest,
  EntityGoogleConnectionRequest,
  EntityReviewProviderDetails,
  EntityUploadResponse,
  GlobalEntityResponse,
  PreviewEntityData,
} from '../../../../types/model/entity';
import type { PaginationRequest } from '../../../../types/model/shared';
import type { ImportSourceUrlResponse } from '../../../../types/model/source';
import type { GroupAttributeValue } from '../../../../types/ui/segmentGroups';
import { $assApi } from '../../../api';
import {
  ADMIN_ENTITIES_BASE_PATH,
  ADMIN_ORGANIZATION_BASE_PATH,
  CONNECTION_BASE_PATH,
  ENTITIES_BASE_PATH,
  ENTITY_CONNECTIONS_BASE_PATH,
  ORGANIZATION_BASE_PATH,
} from '../../path';

export async function fetchEntitiesByIds(entityIds: string[]): Promise<BasicEntity[]> {
  const { data } = await $assApi.post(`${ORGANIZATION_BASE_PATH}/get_owned_entities_by_id`, {
    entity_ids: entityIds,
  });
  return data.data;
}

export async function fetchEntities(
  { limit, offset }: PaginationRequest,
  sortKey: string,
  searchKey?: string,
  organizationId?: string
): Promise<EntitiesResponse> {
  let url = `${ORGANIZATION_BASE_PATH}/get_owned_entities`;
  if (organizationId) {
    url = url.replace('{organization_id}', organizationId);
  }
  const { data } = await $assApi.get(url, {
    params: {
      limit,
      offset,
      order: sortKey,
      ...(searchKey && { q: searchKey }),
    },
  });
  return data;
}

export async function fetchAddableEntities(
  { limit, offset }: PaginationRequest,
  sortKey: string,
  searchKey?: string
) {
  const { data } = await $assApi.get<EntitiesResponse>(
    `${ORGANIZATION_BASE_PATH}/search_addable_entities`,
    {
      params: {
        limit,
        offset,
        order: sortKey,
        ...(searchKey && { q: searchKey }),
      },
    }
  );
  return data;
}

export async function fetchEntitiesAdmin(
  { limit, offset }: PaginationRequest,
  sortKey: string,
  searchKey?: string,
  organizationId?: string
): Promise<EntitiesResponse> {
  const { data } = await $assApi.get(`${ADMIN_ORGANIZATION_BASE_PATH}/get_owned_entities`, {
    params: {
      limit,
      offset,
      order: sortKey,
      ...(searchKey && { q: searchKey }),
      ...(organizationId && { organization_id: organizationId }),
    },
  });
  return data;
}

export async function fetchEntity(entityId: string): Promise<Entity> {
  const { data } = await $assApi.get(`${ORGANIZATION_BASE_PATH}/get_owned_entity`, {
    params: {
      entity_id: entityId,
    },
  });
  return data.data;
}

export async function fetchEntityAdmin(entityId: string, organizationId: string): Promise<Entity> {
  const { data } = await $assApi.get(`${ADMIN_ORGANIZATION_BASE_PATH}/get_owned_entity`, {
    params: {
      entity_id: entityId,
      organization_id: organizationId,
    },
  });
  return data.data;
}

export async function fetchEntityCount(organizationId?: string): Promise<number> {
  let url = `${ORGANIZATION_BASE_PATH}/get_owned_entities`;
  if (organizationId) {
    url = url.replace('{organization_id}', organizationId);
  }
  // TODO: Replace this with get entity count api when its ready
  const { data } = await $assApi.get(url, {
    params: {
      limit: 1,
      offset: 0,
    },
  });
  return data.pagination.total;
}

export async function fetchEntityCountAdmin(organizationId?: string): Promise<number> {
  // TODO: Replace this with get entity count api when its ready
  const { data } = await $assApi.get(`${ADMIN_ORGANIZATION_BASE_PATH}/get_owned_entities`, {
    params: {
      limit: 1,
      offset: 0,
      organization_id: organizationId,
    },
  });
  return data.pagination.total;
}

export async function fetchAttributes(): Promise<string[]> {
  const { data } = await $assApi.get(`${ORGANIZATION_BASE_PATH}/get_attributes`);
  return data.data;
}

export async function fetchAttributesForRules(): Promise<string[]> {
  const { data } = await $assApi.get(`${ORGANIZATION_BASE_PATH}/get_attributes_for_rules`);
  return data.data;
}

export async function fetchAttributeValues(attribute?: string): Promise<GroupAttributeValue[]> {
  if (!attribute) return [];
  const { data } = await $assApi.get(`${ORGANIZATION_BASE_PATH}/get_attribute_values`, {
    params: {
      attribute,
    },
  });
  return data.data;
}

export async function fetchAttributeValuesForRules(
  attribute?: string,
  searchValue?: string
): Promise<GroupAttributeValue[]> {
  if (!attribute) return [];
  const { data } = await $assApi.get(
    `${ORGANIZATION_BASE_PATH}/get_organization_attribute_values`,
    {
      params: {
        attribute,
        q: searchValue,
        limit: 200,
      },
    }
  );
  return data.data;
}

export async function fetchGroupEntities(
  groupId: string,
  { limit, offset }: PaginationRequest,
  sortKey: string,
  searchKey?: string
): Promise<EntitiesResponse> {
  const { data } = await $assApi.get(`${ORGANIZATION_BASE_PATH}/get_owned_entities`, {
    params: {
      group_id: groupId,
      limit,
      offset,
      order: sortKey,
      ...(searchKey && { q: searchKey }),
    },
  });
  return data;
}

export async function deleteEntity(entityId: string): Promise<string> {
  const { data } = await $assApi.delete(`${ORGANIZATION_BASE_PATH}/delete_owned_entity`, {
    params: {
      entity_id: entityId,
    },
  });
  return data;
}

export async function deleteEntityAdmin(entityId: string): Promise<string> {
  const { data } = await $assApi.delete(`${ADMIN_ORGANIZATION_BASE_PATH}/delete_entity`, {
    params: {
      entity_id: entityId,
    },
  });
  return data;
}

export async function uploadEntities(formData: FormData): Promise<PreviewEntityData> {
  const { data } = await $assApi.post(`${ORGANIZATION_BASE_PATH}/upload_owned_entities`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data.data;
}

export async function uploadEntitiesAdmin(formData: FormData): Promise<EntityUploadResponse> {
  const { data } = await $assApi.post(`${ADMIN_ENTITIES_BASE_PATH}/upload_entities`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
}

export async function uploadCompetitorsAdmin(
  formData: FormData,
  organizationId: string
): Promise<CompetitorsUploadResponse> {
  const { data } = await $assApi.post(
    `${ADMIN_ORGANIZATION_BASE_PATH}/${organizationId}/upload_competitors`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  return data;
}

export async function uploadSourcesAdmin(formData: FormData): Promise<ImportSourceUrlResponse> {
  const { data } = await $assApi.post(`${ADMIN_ENTITIES_BASE_PATH}/import_sources_urls`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
}

export async function addEntities(entities: EntitiesPayload): Promise<string> {
  const { data } = await $assApi.post(`${ORGANIZATION_BASE_PATH}/add_owned_entities`, entities);
  return data;
}

export async function fetchGlobalEntities(
  { limit, offset }: PaginationRequest,
  sortKey: string,
  searchKey?: string
): Promise<GlobalEntityResponse> {
  const { data } = await $assApi.get(`${ADMIN_ENTITIES_BASE_PATH}/get_entities`, {
    params: {
      limit,
      offset,
      order: sortKey,
      ...(searchKey && { q: searchKey }),
    },
  });
  return data;
}

export async function createEntity(entity: CreateEntity): Promise<CreateEntityResponse> {
  const { data } = await $assApi.post(`${ENTITIES_BASE_PATH}/create_entity`, entity);
  return data;
}

export async function editEntity(entity: EditEntity): Promise<EditEntityResponse> {
  const { data } = await $assApi.post(`${ENTITIES_BASE_PATH}/update_entity`, entity);
  return data;
}

export async function isValidEntityName(name: string): Promise<boolean> {
  const { data } = await $assApi
    .get(`${ENTITIES_BASE_PATH}/is_valid_entity_name`, {
      params: {
        name,
      },
    })
    .then((response) => response.data)
    .catch(() => ({ data: { valid: false } }));

  return data.valid;
}

export async function fetchDownloadTemplate(organizationId: string): Promise<string> {
  const { data } = await $assApi.get(`${ORGANIZATION_BASE_PATH}/download_template`, {
    params: {
      organization_id: organizationId,
    },
  });
  return data;
}

export async function exportEntities(
  sortKey: string,
  searchKey?: string,
  groupId?: string
): Promise<string> {
  const { data } = await $assApi.get(`${ORGANIZATION_BASE_PATH}/export_owned_entities`, {
    params: {
      order: sortKey,
      ...(searchKey && { q: searchKey }),
      ...(groupId && { group_id: groupId }),
    },
  });
  return data;
}

export async function fetchEntityReviewProviders(entityId: string): Promise<ReviewProvider[]> {
  const { data } = await $assApi.get(`${ENTITY_CONNECTIONS_BASE_PATH}/get_review_providers`, {
    params: {
      entity_id: entityId,
    },
  });
  return data.data;
}

export async function createEntityBookingConnection(
  request: EntityBookingConnectionRequest
): Promise<BookingConnectionResponse> {
  const { data } = await $assApi.post(
    `${ENTITY_CONNECTIONS_BASE_PATH}/create_booking_provider_account`,
    request
  );
  return data.data;
}

export async function createEntityGoogleConnection(
  request: EntityGoogleConnectionRequest
): Promise<GoogleConnectionResponse> {
  const { data } = await $assApi.post(
    `${ENTITY_CONNECTIONS_BASE_PATH}/create_google_provider_account`,
    request
  );
  return data.data;
}

export async function deleteEntityReviewProviderAccount(id: string): Promise<string> {
  const { data } = await $assApi.delete(`${ENTITY_CONNECTIONS_BASE_PATH}/delete_provider_account`, {
    params: {
      provider_account_id: id,
    },
  });
  return data;
}

export async function fetchEntityReviewProviderDetails(
  entityId: string,
  reviewProviderId: string
): Promise<EntityReviewProviderDetails> {
  const { data } = await $assApi.get(
    `${ENTITY_CONNECTIONS_BASE_PATH}/get_review_provider_connections`,
    {
      params: {
        review_provider_id: reviewProviderId,
        entity_id: entityId,
      },
    }
  );
  return data;
}
