import { useIntl } from 'react-intl';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import type { GridPaginationModel } from '@mui/x-data-grid-pro';
import { faArrowUpRightAndArrowDownLeftFromCenter } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  FETCH_ENTITIES,
  SEARCH_DEBOUNCE_TIME_MS,
  commonFields,
  getPaginationRequest,
  getSortRequest,
  searchPlaceholders,
  useEntities,
  useEntitiesGridStore,
  useGridParams,
  useRouteUtils,
} from '@trustyou/shared';
import {
  DataGrid,
  StyledFontAwesomeIcon as Icon,
  IconButton,
  SearchBar,
  Stack,
  Tooltip,
} from '@trustyou/ui';

import { entityMessage } from '../../../../constants/messages/entities';
import NameCell from '../Common/NameCell';
import styles from './styles';

export const MiniEntityTable = () => {
  const navigate = useNavigate();
  const { entityId } = useParams();
  const { navigateWithBase, navigateWithEntityDetails } = useRouteUtils();
  const gridState = useEntitiesGridStore();
  const { state: locationState, pathname } = useLocation();
  const intl = useIntl();

  const { paginationModel: gridPaginationModel, sortModel, searchKey } = gridState;
  const paginationModel = { ...gridPaginationModel, ...locationState?.paginationModel };

  const { onPaginationModelChange, onSortModelChange, onSearch } = useGridParams(
    FETCH_ENTITIES,
    gridState
  );

  const { data, isFetching } = useEntities(
    getPaginationRequest(paginationModel),
    getSortRequest(sortModel),
    searchKey
  );

  const onPaginationModelChangeHandler = (model: GridPaginationModel) => {
    onPaginationModelChange(model);
    if (locationState?.paginationModel) {
      navigate(pathname, { replace: true });
    }
  };

  const onClose = () => {
    navigateWithBase('organization/entities');
  };

  const rows = data?.data.map((row, rowId) => ({ ...row, rowId })) || [];

  return (
    <Stack gap={1} sx={styles.tableContainer}>
      <Stack direction="row" gap={1}>
        <SearchBar
          onSearch={onSearch}
          debounceTime={SEARCH_DEBOUNCE_TIME_MS}
          placeholder={intl.formatMessage(searchPlaceholders.searchEntities)}
          id="mini-entity-table"
          variant="standard"
          defaultValue={searchKey}
          sx={{ width: '100%' }}
        />
        <Tooltip placement="top" arrow title={intl.formatMessage(entityMessage.detailsExpandIcon)}>
          <IconButton onClick={onClose}>
            <Icon size="xs" icon={faArrowUpRightAndArrowDownLeftFromCenter} />
          </IconButton>
        </Tooltip>
      </Stack>
      <DataGrid
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        paginationMode="server"
        onPaginationModelChange={onPaginationModelChangeHandler}
        rowCount={data?.pagination.total}
        sx={styles.table}
        rowHeight={64}
        columns={[
          {
            field: 'name',
            headerName: intl.formatMessage(commonFields.name),
            renderCell: ({ row }) => <NameCell name={row.name} sector={row.sector} />,
            disableColumnMenu: true,
            flex: 1,
          },
        ]}
        rows={rows}
        getRowId={(row) => row.rowId}
        onRowClick={(params) => navigateWithEntityDetails(params.row.id)}
        loading={isFetching}
        paginationModel={paginationModel}
        autoHeight={false}
        containerStyle={styles.table}
        getRowClassName={(params) => (params.row.id === entityId ? 'selected-row' : '')}
      />
    </Stack>
  );
};
